import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../api';
import Breadcrumbs from '../components/Breadcrumbs';
import './styles/Tests.css';

const Tests = () => {
    const [tests, setTests] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [testName, setTestName] = useState('');
    const [editTestId, setEditTestId] = useState(null);
    const [active, setActive] = useState(false);  // Новое состояние

    useEffect(() => {
        fetchTests(currentPage);
    }, [currentPage]);

    const fetchTests = async (page) => {
        try {
            const response = await api.get(`/tests?page=${page}`);
            setTests(response.data.data.tests);
            setTotalPages(response.data.data.pages);
        } catch (error) {
            console.error('Error fetching tests:', error);
        }
    };

    const handleCreateOrUpdateTest = async () => {
        try {
            const data = { test_name: testName, active: active };  // Отправляем active
            if (editTestId) {
                await api.put(`/tests/${editTestId}`, data);
            } else {
                await api.post('/tests', data);
            }
            setTestName('');
            setActive(false);  // Сбрасываем значение active
            setEditTestId(null);
            fetchTests(currentPage);
        } catch (error) {
            console.error('Error saving test:', error);
        }
    };

    const handleDeleteTest = async (testId) => {
        try {
            await api.delete(`/tests/${testId}`);
            fetchTests(currentPage);
        } catch (error) {
            console.error('Error deleting test:', error);
        }
    };

    const handleEditTest = (test) => {
        setTestName(test.test_name);
        setActive(test.active);  // Устанавливаем значение active
        setEditTestId(test.test_id);
    };

    return (
        <div className="container">
            <Breadcrumbs />
            <h1>Управление анализами</h1>
            <div className="card">
                <input
                    type="text"
                    placeholder="Название анализа"
                    value={testName}
                    onChange={(e) => setTestName(e.target.value)}
                />
                <label>
                    <input
                        type="checkbox"
                        checked={active}
                        onChange={(e) => setActive(e.target.checked)}
                    />
                    Активен
                </label>
                <button onClick={handleCreateOrUpdateTest}>
                    {editTestId ? 'Обновить анализ' : 'Создать анализ'}
                </button>
            </div>
            <div className="card">
                <table>
                    <thead>
                    <tr>
                        <th>ID</th>
                            <th>Название анализа</th>
                            <th>Действия</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tests.map((test) => (
                            <tr key={test.test_id}>
                                <td>{test.test_id}</td>
                                <td><Link to={`/admin/tests/${test.test_id}`}>{test.test_name}</Link></td>
                                <td>
                                    <button onClick={() => handleEditTest(test)}>Редактировать</button>
                                    <button onClick={() => handleDeleteTest(test.test_id)}>Удалить</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="pagination">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index + 1}
                            onClick={() => setCurrentPage(index + 1)}
                            className={index + 1 === currentPage ? 'active' : ''}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Tests;
