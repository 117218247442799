import React, { useState, useEffect } from 'react';
import api from '../api';
import AddResultForm from '../components/AddResultForm';
import Modal from '../components/Modal';
import TestCard from '../components/TestCard';
import TestDetailsModal from '../components/TestDetailsModal';
import './styles/MyTests.css';

const MyTests = () => {
    const [showForm, setShowForm] = useState(false);
    const [tests, setTests] = useState([]);
    const [resultsByTest, setResultsByTest] = useState({});
    const [selectedTest, setSelectedTest] = useState(null);

    useEffect(() => {
        fetchTests();
    }, []);

    const fetchTests = async () => {
        try {
            const response = await api.get('/tests');
            setTests(response.data.data.tests);
            fetchUserResultsByTests(response.data.data.tests);
        } catch (error) {
            console.error('Error fetching tests:', error);
        }
    };

    const fetchUserResultsByTests = async (tests) => {
        const results = {};
        for (let test of tests) {
            const response = await api.get(`/user-results/${test.test_id}`);
            results[test.test_id] = response.data.data;
        }
        setResultsByTest(results);
    };

    const handleResultAdded = () => {
        setShowForm(false);
        fetchUserResultsByTests(tests);
    };

    const userTests = tests.filter(test => resultsByTest[test.test_id] && resultsByTest[test.test_id].dates);

    const handleCardClick = (testId) => {
        setSelectedTest(testId);
    };

    const handleCloseDetails = () => {
        setSelectedTest(null);
    };

    return (
        <div className="container">
            <h1>Мои анализы</h1>
            <button className="add-button" onClick={() => setShowForm(true)}>+</button>
            {showForm && (
                <Modal onClose={() => setShowForm(false)}>
                    <AddResultForm tests={tests} onResultAdded={handleResultAdded} />
                </Modal>
            )}
            <div className="test-cards">
                {userTests.length > 0 ? (
                    userTests.map((test) => {
                        const lastResultIndex = resultsByTest[test.test_id].dates.length - 1;
                        const lastTestDate = resultsByTest[test.test_id].dates[lastResultIndex];
                        const lastParameters = resultsByTest[test.test_id].parameters.map(param => ({
                            name: param.name,
                            value: param.values[lastResultIndex].value,
                            is_reference: param.values[lastResultIndex].is_reference
                        }));
                        return (
                            <TestCard
                                key={test.test_id}
                                testId={test.test_id}
                                testName={test.test_name}
                                lastTestDate={lastTestDate}
                                parameters={lastParameters}
                                onClick={() => handleCardClick(test.test_id)}
                            />
                        );
                    })
                ) : (
                    <p>Нет данных</p>
                )}
            </div>
            {selectedTest && (
                <Modal onClose={handleCloseDetails}>
                    <TestDetailsModal
                        testId={selectedTest}
                        testName={tests.find(test => test.test_id === selectedTest).test_name}
                        onClose={handleCloseDetails}
                    />
                </Modal>
            )}
        </div>
    );
};

export default MyTests;
