import React from 'react';
import { Link } from 'react-router-dom';

const Admin = () => {
    return (
        <div className="container">
            <h1>Админка</h1>
            <ul>
                <li>
                    <Link to="/admin/tests">Управление анализами</Link>
                </li>
                <li>
                    <Link to="/admin/species">Управление видами питомцев</Link>
                </li>
                <li>
                    <Link to="/admin/genders">Управление видами полов (genders)</Link>
                </li>
            </ul>
        </div>
    );
};

export default Admin;
